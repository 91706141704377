import React from 'react'
import "./footerStyle.css";
import SocialNetwork from '../common/social/SocialNetwork';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='footerContainer'>
        <div className='title'>
        <p>Contact us</p>
      </div>
      <SocialNetwork
      WhatsApp={'https://wa.me/9720507554006'}
      Facebook={
        "https://www.facebook.com/profile.php?id=61561790361841"
      }
      Phone={"tel:+9720507554006"}
      Message={
        "sms:+9520507554006?&body=היי, אני מעוניין בבניית אתר לעסק שלי, אשמח שתחזרו אלי"
      }
      />
        <div className={"policyContainer"}>
        <Link to={"/termsConditions"}>
        <p className={"privacy"}>terms & conditions</p>
        </Link>
        <Link to={"/privacyPolicy"}>
        <p className={"privacy"}>privacy policy</p>
        </Link>
      </div>
    </div>
  )
}

export default Footer