import React from "react";
import { Helmet } from "react-helmet-async";

const SEO = () => {
  return (
    <Helmet>
      <title>AB Studio</title>
      <meta
        name="keywords"
        content={"פיתוח אתר, עיצוב אתר, בניית אתרים, אתר לעסק, פתרונות דיגיטליים, בית תוכנה,"}
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={"https://ab-sandy.vercel.app/logo_img.png"}
      />
      <meta
        property="og:image:alt"
        content={"לוגו של איי-בי"}
      />
      <meta property="og:url" content={"https://ab-sandy.vercel.app"} />
      <meta property="og:title" content={"AB Web"} />
      <meta property="og:description" content={"שירותי פיתוח אתרים מתקדמים. מומחים בבניית אתרים מותאמים אישית עבור העסק, מספקים פתרונות דיגיטליים מקצה לקצה ברמה הגבוהה ביותר."} />
    </Helmet>
  );
};

export default SEO;
