import React from "react";
// import PrivacyPolicyPage from "./PrivacyPolicyPage.jsx" 

const HomePage = React.lazy(()=> import("./HomePage"));
const AboutPage = React.lazy(()=> import("./AboutPage"));
const ServicesPage = React.lazy(()=> import("./ServicesPage"));
const ContactPage = React.lazy(()=> import("./ContactPage"));
const GrpahicDesignPage = React.lazy(()=> import("./GrpahicDesignPage"));
const TermsConditionsPage = React.lazy(()=> import("./TermsConditionsPage"));
const PrivacyPolicyPage = React.lazy(()=> import("./PrivacyPolicyPage"));


const mainRoutes = [
    {isNavbar: true ,linkLabel: "Home", path: "/", component: HomePage},
    {isNavbar: true ,linkLabel: "About", path: "/about", component: AboutPage},
    {isNavbar: true ,linkLabel: "Services", path: "/services", component: ServicesPage},
    {isNavbar: true ,linkLabel: "Contact", path: "/contact", component: ContactPage},
    {isNavbar: false ,linkLabel: "Grpahic Design", path: "/GrpahicDesignPage", component: GrpahicDesignPage},
    {isNavbar: false ,linkLabel: "privacy policy", path: "/privacyPolicy", component: PrivacyPolicyPage},
    {isNavbar: false ,linkLabel: "terms & conditions", path: "/termsConditions", component: TermsConditionsPage},
];

export {mainRoutes};